
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import { useDeals } from '@/compositions/deals/useDeals'

export default defineComponent({
  components: {
    PageContent,
    TmButton,
    DetailsHero,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const { openTemplateModal } = useDeals()
    const breadcrumbsLink = ref<BreadcrumbsLink[]>([
      {
        label: 'Deals',
        name: 'base.deals.index',
      },
      {
        label: 'Boards',
      },
    ])

    return {
      breadcrumbsLink,
      openTemplateModal,
    }
  },
})
